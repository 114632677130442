import {
  CalendarDaysIcon,
  CheckCircleIcon,
  ChevronRightIcon,
  ClockIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { Avatar, Text } from "@switchdreams/ui";
import { createColumnHelper } from "@tanstack/react-table";
import React from "react";

import { formatIntegerCurrency } from "../../utils";
import Table from "./index";

const user = (name, image) => {
  return (
    <div className="flex items-center gap-2">
      <Avatar name={name} size="lg" avatarUrl={image} />
      <Text className="text-md font-poppins font-medium text-coolGray-950">{name}</Text>
    </div>
  );
};

const status = (statusName) => {
  const statusTranslate = {
    cancelled: "Cancelada",
    completed: "Finalizada",
  };
  return (
    <div className="font-regular flex items-center gap-2 font-poppins text-sm text-coolGray-700">
      {statusName == "completed" ? (
        <CheckCircleIcon className="h-4" />
      ) : (
        <XCircleIcon className="h-4" />
      )}
      <Text>{statusTranslate[statusName]}</Text>
    </div>
  );
};

const Timer = (time) => {
  return (
    <div className="font-regular flex items-center gap-2 font-poppins text-sm text-coolGray-700">
      <ClockIcon className="h-4" />
      <Text>{time}</Text>
    </div>
  );
};

const DateFormatter = (dateObj) => {
  const date = new Date(dateObj);
  return (
    <div className="font-regular flex items-center gap-2 font-poppins text-sm text-coolGray-700">
      <CalendarDaysIcon className="h-4" />
      <Text>{`${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`}</Text>
    </div>
  );
};

const ValueFormatter = (value) => {
  return (
    <div className="font-regular flex items-center gap-2 font-poppins text-sm text-coolGray-700">
      <Text>{formatIntegerCurrency(value)}</Text>
    </div>
  );
};

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor("pacient", {
    header: () => "Paciente",
    cell: (info) => user(info.row.original.patient_name, info.row.original.patient_image),
  }),
  columnHelper.accessor("data", {
    header: () => "Data de sessão",
    cell: (info) => DateFormatter(info.row.original.date),
  }),
  columnHelper.accessor("hour", {
    header: () => "Horário de sessão",
    cell: (info) => Timer(info.row.original.approximate_start_time),
  }),
  columnHelper.accessor("status", {
    header: () => "Status",
    cell: (info) => status(info.row.original.status),
  }),
  columnHelper.accessor("value", {
    header: () => "Valor",
    cell: (info) => ValueFormatter(info.row.original.transfer_value_cents),
  }),
  columnHelper.accessor("options", {
    header: "",
    cell: () => <CustomCell />,
  }),
];

const CustomCell = () => {
  return (
    <div className="flex justify-center gap-2">
      <ChevronRightIcon className="h-5" />
    </div>
  );
};

const MonthlyIncomeTable = ({ info }) => {
  return (
    <Table
      data={info}
      columns={columns}
      defaultMessage="Sem consultas feitas"
      classNameTable="border border-coolgray-100 shadow-md rounded-lg"
    />
  );
};

export default MonthlyIncomeTable;
