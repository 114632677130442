import {
  BriefcaseIcon,
  CameraIcon,
  MapPinIcon,
  PlusIcon,
  UserCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useForm } from "@inertiajs/react";
import {
  Avatar,
  Button,
  SelectBox,
  Text,
  TextArea,
  TextField,
  TextFieldMask,
} from "@switchdreams/ui";
import axios from "axios";
import React, { useEffect, useState } from "react";

import { genderOptions, stateOptions } from "@/constants/SelectOptions";
import { useAlert } from "@/contexts/Alert";
import { monthsTranslated, pixTypeMask, pixTypeOptions, priceOptions } from "@/utils";

import AddImageModal from "../AddImageModal";

const EditProfile = ({ user, data, setData, areaOptions, clinic }) => {
  const [open, setOpen] = useState(false);
  const [openClinicModal, setOpenClinicModal] = useState(false);
  const { showAlert } = useAlert();
  const fileForm = useForm({ image: null });
  const fileFormClinic = useForm({ image: null });

  const formatDateOfRegister = () => {
    const date = user.registered_since.split("/");
    return `${date[0]} de ${monthsTranslated[Number(date[1]) - 1]} de ${date[2]}`;
  };

  const [specialtyOptions, setSpecialtyOptions] = useState([]);

  const getSpecialties = (id) => {
    axios
      .get(`/work_specialties/${id}`)
      .then((response) => setSpecialtyOptions(response.data))
      .catch(() => showAlert({ message: "Ocorreu um erro. Tente novamente", type: "warning" }));
  };

  const updateUserImage = () => {
    fileForm.patch(`/users/${user.id}/attach_image`, {
      onError: (e) => {
        showAlert({ message: e });
      },
      onSuccess: () => {
        showAlert({ message: "Dados de usuário editados com sucesso!", type: "success" });
      },
    });
    setOpen(false);
  };

  const updateClinicImage = () => {
    fileFormClinic.patch("/clinica/attach_image", {
      onError: (e) => {
        showAlert({ message: e });
      },
      onSuccess: () => {
        showAlert({ message: "Dados da clinica editados com sucesso!", type: "success" });
      },
    });
    setOpenClinicModal(false);
  };

  useEffect(() => {
    getSpecialties(data.doctor_attributes.work_area_id);
  }, []);

  return (
    <div className="flex gap-6 px-4 py-6 max-lg:flex-col">
      <AddImageModal
        setOpen={setOpen}
        open={open}
        currentImage={user.image}
        onClickConfirm={updateUserImage}
        fileForm={fileForm}
      />
      <div className="flex items-center gap-6 rounded-xl border border-coolGray-300 px-5 py-4 lg:h-fit lg:flex-col lg:px-14 lg:pt-8">
        <div
          className="relative flex size-28 cursor-pointer items-center justify-center rounded-full border-2 border-secondary-400"
          onClick={() => setOpen(true)}
        >
          <Avatar name={user.name} size="2xl" className="-z-10 size-28" avatarUrl={user.image} />
          <div className="absolute right-0 top-0 flex size-8 items-center justify-center rounded-full bg-secondary-400">
            <CameraIcon className="size-5 text-white" />
          </div>
        </div>
        <div className="lg:flex lg:flex-col lg:items-center lg:justify-center">
          <Text className="font-quickSand text-center text-2xl font-semibold text-coolGray-950">
            {user.name}
          </Text>
          <Text className="font-regular font-poppins text-sm text-coolGray-600">
            {user?.doctor?.work_specialty?.name}
          </Text>
          <div className="mt-8 flex flex-col items-center justify-center border-t border-coolGray-300 pt-8 max-lg:hidden">
            <Text className="font-regular font-poppins text-xs">Cadastrado desde</Text>
            <Text className="font-regular font-poppins text-xs">{formatDateOfRegister()}</Text>
          </div>
        </div>
      </div>

      <div className="flex flex-col lg:w-[70%]">
        <div className="flex flex-col items-start gap-6 rounded-xl border border-coolGray-300">
          <div className="flex w-full items-center gap-3 border-b border-coolGray-300 px-5 py-4">
            <UserCircleIcon className="size-6 text-secondary-500" />
            <Text className="font-quickSand text-xl font-semibold text-coolGray-950">Pessoal</Text>
          </div>
          <div className="w-full px-6">
            <div className="flex w-full max-lg:flex-col lg:gap-6">
              <div className="lg:w-1/2">
                <TextField
                  label="Nome"
                  className="rounded-xl"
                  value={data.name}
                  onChange={(e) => setData("name", e.target.value)}
                />
              </div>
              <div className="lg:w-1/2">
                <TextFieldMask
                  name="cpf"
                  mask="999.999.999-99"
                  label="CPF"
                  type="text"
                  className="rounded-xl"
                  value={data.cpf}
                  onChange={(e) => setData("cpf", e.target.value)}
                />
              </div>
            </div>
            <div className="flex w-full max-lg:flex-col lg:gap-6">
              <div className="lg:w-1/2">
                <SelectBox
                  label="Gênero"
                  className="rounded-xl"
                  options={genderOptions}
                  value={data.gender}
                  onChange={(e) => setData("gender", e)}
                />
              </div>
              <div className="lg:w-1/2">
                <TextFieldMask
                  name="phone"
                  mask="(99) 99999-9999"
                  label="Telefone"
                  type="text"
                  className="rounded-xl"
                  value={data.phone}
                  onChange={(e) => setData("phone", e.target.value)}
                />
              </div>
            </div>
            <div className="lg:w-[48%]">
              <TextField
                label="Data de nascimento"
                className="rounded-xl"
                type="date"
                value={data.birthdate}
                onChange={(e) => setData("birthdate", e.target.value)}
              />
            </div>
          </div>

          {user.role === "doctor" && (
            <>
              <div className="flex w-full items-center gap-3 border-y border-coolGray-300 px-5 py-4">
                <BriefcaseIcon className="size-6 text-secondary-500" />
                <Text className="font-quickSand text-xl font-semibold text-coolGray-950">
                  Profissional
                </Text>
              </div>
              <div className="w-full px-6">
                <div className="flex w-full max-lg:flex-col lg:gap-6">
                  <div className="lg:w-1/2">
                    <SelectBox
                      label="Conselho de Classe"
                      className="rounded-xl"
                      options={areaOptions}
                      value={data.doctor_attributes.work_area_id}
                      onChange={(e) => {
                        setData((data) => ({
                          ...data,
                          doctor_attributes: {
                            ...data.doctor_attributes,
                            work_area_id: e,
                            work_specialty_id: null,
                          },
                        }));
                        getSpecialties(e);
                      }}
                    />
                  </div>
                  <div className="lg:w-1/2">
                    <TextField
                      label="N° do Conselho de classe"
                      className="rounded-xl"
                      value={data.doctor_attributes.council}
                      onChange={(e) => {
                        setData((data) => ({
                          ...data,
                          doctor_attributes: {
                            ...data.doctor_attributes,
                            council: e.target.value,
                          },
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="lg:w-[48%]">
                  <SelectBox
                    label="Especialidade"
                    className="rounded-xl"
                    options={specialtyOptions}
                    value={data.doctor_attributes.work_specialty_id}
                    onChange={(e) => {
                      setData((data) => ({
                        ...data,
                        doctor_attributes: {
                          ...data.doctor_attributes,
                          work_specialty_id: e,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </>
          )}
          <div className="flex w-full items-center gap-3 border-y border-coolGray-300 px-5 py-4">
            <MapPinIcon className="size-6 text-secondary-500" />
            <Text className="font-quickSand text-xl font-semibold text-coolGray-950">Endereço</Text>
          </div>
          <div className="w-full px-6 pb-10">
            <div className="flex w-full max-lg:flex-col lg:gap-6">
              <div className="lg:w-1/2">
                <TextFieldMask
                  mask="99999-999"
                  label="CEP"
                  className="rounded-xl"
                  value={data.address_attributes.cep}
                  onChange={(e) => {
                    setData((data) => ({
                      ...data,
                      address_attributes: {
                        ...data.address_attributes,
                        cep: e.target.value,
                      },
                    }));
                  }}
                />
              </div>
              <div className="lg:w-1/2">
                <SelectBox
                  label="Estado"
                  className="rounded-xl"
                  options={stateOptions}
                  value={data.address_attributes.state}
                  onChange={(e) => {
                    setData((data) => ({
                      ...data,
                      address_attributes: {
                        ...data.address_attributes,
                        state: e,
                      },
                    }));
                  }}
                />
              </div>
            </div>
            <div className="flex w-full max-lg:flex-col lg:gap-6">
              <div className="lg:w-1/2">
                <TextField
                  name="city"
                  label="Cidade"
                  className="rounded-xl"
                  value={data.address_attributes.city}
                  onChange={(e) => {
                    setData((data) => ({
                      ...data,
                      address_attributes: {
                        ...data.address_attributes,
                        city: e.target.value,
                      },
                    }));
                  }}
                />
              </div>
              <div className="lg:w-1/2">
                <TextField
                  name="neighborhood"
                  label="Bairro"
                  className="rounded-xl"
                  value={data.address_attributes.neighborhood}
                  onChange={(e) => {
                    setData((data) => ({
                      ...data,
                      address_attributes: {
                        ...data.address_attributes,
                        neighborhood: e.target.value,
                      },
                    }));
                  }}
                />
              </div>
            </div>
            <div className="flex w-full max-lg:flex-col lg:gap-6">
              <div className="lg:w-1/2">
                <TextField
                  name="street"
                  label="Logradouro"
                  className="rounded-xl"
                  value={data.address_attributes.street}
                  onChange={(e) => {
                    setData((data) => ({
                      ...data,
                      address_attributes: {
                        ...data.address_attributes,
                        street: e.target.value,
                      },
                    }));
                  }}
                />
              </div>
              <div className="lg:w-1/2">
                <TextField
                  name="number"
                  label="Número"
                  className="rounded-xl"
                  value={data.address_attributes.number}
                  onChange={(e) => {
                    setData((data) => ({
                      ...data,
                      address_attributes: {
                        ...data.address_attributes,
                        number: e.target.value,
                      },
                    }));
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        {clinic && (
          <div className="mt-6 flex flex-col items-start gap-6 rounded-xl border border-coolGray-300 pb-6">
            <div className="flex w-full items-center gap-3 border-b border-coolGray-300 px-5 py-4">
              <UserCircleIcon className="size-6 text-secondary-500" />
              <div className="flex flex-col">
                <Text className="font-quickSand text-xl font-semibold text-coolGray-950">
                  Dados da Clínica
                </Text>
              </div>
            </div>

            <div className="w-full px-6">
              <AddImageModal
                setOpen={setOpenClinicModal}
                open={openClinicModal}
                currentImage={clinic.image}
                onClickConfirm={() => updateClinicImage()}
                fileForm={fileFormClinic}
              />
              <div
                className="relative flex size-28 cursor-pointer items-center justify-center rounded-full border-2 border-secondary-400"
                onClick={() => setOpenClinicModal(true)}
              >
                <Avatar
                  name={clinic.name}
                  size="2xl"
                  className="-z-10 size-28"
                  avatarUrl={clinic.image}
                />
                <div className="absolute right-0 top-0 flex size-8 items-center justify-center rounded-full bg-secondary-400">
                  <CameraIcon className="size-5 text-white" />
                </div>
              </div>
            </div>

            <div className="flex w-full px-6 max-lg:flex-col lg:gap-6">
              <div className="lg:w-1/2">
                <TextField
                  label="Nome"
                  className="rounded-xl"
                  value={data.clinic_attributes.name}
                  onChange={(e) =>
                    setData((data) => ({
                      ...data,
                      clinic_attributes: {
                        ...data.clinic_attributes,
                        name: e.target.value,
                      },
                    }))
                  }
                />
              </div>
              <div className="lg:w-1/2">
                <TextFieldMask
                  name="cnpj"
                  mask="99.999.999/9999-99"
                  label="CNPJ"
                  type="text"
                  className="rounded-xl"
                  value={data.clinic_attributes.cnpj}
                  onChange={(e) =>
                    setData((data) => ({
                      ...data,
                      clinic_attributes: {
                        ...data.clinic_attributes,
                        cnpj: e.target.value,
                      },
                    }))
                  }
                />
              </div>
            </div>

            <div className="w-full px-6">
              <div className="flex w-full max-lg:flex-col lg:gap-6">
                <div className="lg:w-full">
                  <TextArea
                    label="Descrição"
                    placeholder="Escreva um pouco sobre você, sua carreira, suas conquistas, suas experiências, seus objetivos, como funciona sua consulta e/ou sobre seu comprometimento com seus pacientes"
                    className="h-48 rounded-xl"
                    value={data.clinic_attributes.description}
                    onChange={(e) =>
                      setData((data) => ({
                        ...data,
                        clinic_attributes: {
                          ...data.clinic_attributes,
                          description: e.target.value,
                        },
                      }))
                    }
                  />
                </div>
              </div>
            </div>

            <div className="flex w-full px-6 max-lg:flex-col lg:gap-6">
              <div className="lg:w-1/2">
                <SelectBox
                  options={pixTypeOptions}
                  name="pix_type"
                  className="rounded-xl"
                  placeholder="Selecione o tipo da chave pix"
                  label="Tipo da Chave Pix"
                  value={data.clinic_attributes.pix_type}
                  onChange={(e) => {
                    setData((data) => ({
                      ...data,
                      clinic_attributes: {
                        ...data.clinic_attributes,
                        pix_type: e,
                      },
                    }));
                  }}
                />
              </div>
              <div className="lg:w-1/2">
                <TextFieldMask
                  label="Chave Pix"
                  className="rounded-xl"
                  mask={pixTypeMask(data.clinic_attributes.pix_type)}
                  value={data.clinic_attributes.pix_key}
                  onChange={(e) =>
                    setData((data) => ({
                      ...data,
                      clinic_attributes: {
                        ...data.clinic_attributes,
                        pix_key: e.target.value,
                      },
                    }))
                  }
                />
              </div>
            </div>
          </div>
        )}

        {user.role === "doctor" && (
          <div className="mt-6 flex flex-col items-start gap-6 rounded-xl border border-coolGray-300">
            <div className="flex w-full items-center gap-3 border-b border-coolGray-300 px-5 py-4">
              <UserCircleIcon className="size-6 text-secondary-500" />
              <div className="flex flex-col">
                <Text className="font-quickSand text-xl font-semibold text-coolGray-950">
                  Meu Perfil
                </Text>
                <Text className="font-quickSand font-regular text-sm text-coolGray-600">
                  Essas informações aparecerão para os pacientes usuários da plataforma.
                </Text>
              </div>
            </div>

            <div className="w-full px-6">
              <div className="flex w-full max-lg:flex-col lg:gap-6">
                <div className="lg:w-full">
                  <TextArea
                    label="Sobre mim"
                    placeholder="Escreva um pouco sobre você, sua carreira, suas conquistas, suas experiências, seus objetivos, como funciona sua consulta e/ou sobre seu comprometimento com seus pacientes"
                    className="h-48 rounded-xl"
                    value={data.doctor_attributes.description}
                    onChange={(e) =>
                      setData((data) => ({
                        ...data,
                        doctor_attributes: {
                          ...data.doctor_attributes,
                          description: e.target.value,
                        },
                      }))
                    }
                  />
                </div>
              </div>
            </div>

            <div className="w-full border-y border-coolGray-300 px-6 py-4">
              <div className="flex w-full max-lg:flex-col lg:gap-6">
                <div className="lg:w-full">
                  <SelectBox
                    options={priceOptions}
                    name="price"
                    className="rounded-xl"
                    placeholder="Selecione o valor da consulta"
                    label="Valor da consulta"
                    supportText="Em cima do valor selecionado, será cobrada uma taxa de operação que varia de acordo com o plano escolhido."
                    value={data.doctor_attributes.price_cents}
                    onChange={(e) => {
                      setData((data) => ({
                        ...data,
                        doctor_attributes: {
                          ...data.doctor_attributes,
                          price_cents: e,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="w-full border-y border-coolGray-300 px-6 py-4">
              <Text className="font-quickSand pb-3 text-lg font-semibold text-coolGray-900">
                Experiências Profissionais
              </Text>
              {data.doctor_attributes.professional_experiences.map((experience, index) => (
                <div className="flex w-full lg:gap-6" key={index}>
                  <div className="lg:w-full">
                    <TextField
                      placeholder="ex: Hospital Israelita Albert Einstein, internação particular"
                      className="rounded-xl"
                      value={experience}
                      onChange={(e) => {
                        let dataCopy = data.doctor_attributes.professional_experiences;
                        dataCopy[index] = e.target.value;
                        setData((data) => ({
                          ...data,
                          doctor_attributes: {
                            ...data.doctor_attributes,
                            professional_experiences: dataCopy,
                          },
                        }));
                      }}
                    />
                  </div>
                  <div className="flex cursor-pointer items-center justify-center">
                    <XMarkIcon
                      className="size-6 hover:text-primary-400"
                      onClick={() => {
                        if (data.doctor_attributes.professional_experiences.length > 1) {
                          const new_array = data.doctor_attributes.professional_experiences;
                          new_array.splice(index, 1);
                          setData((data) => ({
                            ...data,
                            doctor_attributes: {
                              ...data.doctor_attributes,
                              professional_experiences: new_array,
                            },
                          }));
                        }
                      }}
                    />
                  </div>
                </div>
              ))}
              <Button
                onClick={() => {
                  setData((data) => ({
                    ...data,
                    doctor_attributes: {
                      ...data.doctor_attributes,
                      professional_experiences: [
                        ...data.doctor_attributes.professional_experiences,
                        "",
                      ],
                    },
                  }));
                }}
                icon={PlusIcon}
                iconSide="left"
                className="text-md transition-500 mt-3 rounded-2xl border border-primary-500 font-poppins font-medium text-primary-500 transition-all hover:bg-primary-50 lg:w-60"
                label={"Adicionar Experiência"}
              />
            </div>

            <div className="w-full border-y border-coolGray-300 px-6 py-4">
              <Text className="font-quickSand pb-3 text-lg font-semibold text-coolGray-900">
                Formações Acadêmicas
              </Text>
              {data.doctor_attributes.educational_history.map((experience, index) => (
                <div className="flex w-full lg:gap-6" key={index}>
                  <div className="lg:w-full">
                    <TextField
                      placeholder="ex: Hospital Israelita Albert Einstein, internação particular"
                      className="rounded-xl"
                      value={experience}
                      onChange={(e) => {
                        let dataCopy = data.doctor_attributes.educational_history;
                        dataCopy[index] = e.target.value;
                        setData((data) => ({
                          ...data,
                          doctor_attributes: {
                            ...data.doctor_attributes,
                            educational_history: dataCopy,
                          },
                        }));
                      }}
                    />
                  </div>
                  <div className="flex cursor-pointer items-center justify-center">
                    <XMarkIcon
                      className="size-6 hover:text-primary-400"
                      onClick={() => {
                        if (data.doctor_attributes.educational_history.length > 1) {
                          const new_array = data.doctor_attributes.educational_history;
                          new_array.splice(index, 1);
                          setData((data) => ({
                            ...data,
                            doctor_attributes: {
                              ...data.doctor_attributes,
                              educational_history: new_array,
                            },
                          }));
                        }
                      }}
                    />
                  </div>
                </div>
              ))}
              <Button
                onClick={() => {
                  setData((data) => ({
                    ...data,
                    doctor_attributes: {
                      ...data.doctor_attributes,
                      educational_history: [...data.doctor_attributes.educational_history, ""],
                    },
                  }));
                }}
                icon={PlusIcon}
                iconSide="left"
                className="text-md transition-500 mt-3 rounded-2xl border border-primary-500 font-poppins font-medium text-primary-500 transition-all hover:bg-primary-50 lg:w-60"
                label={"Adicionar Formação"}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditProfile;
